.ScopeList {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 0 1rem 0 1rem;
}

.ScopeList-action {
  display: inline-flex;
  justify-content: right;
  align-items: center;
}
