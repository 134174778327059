.Container {
  padding: var(--spacing-medium);
  border: 4px solid var(--color-lavender);
  border-radius: var(--spacing-x-small);
}

.AccordianContainer {
  padding: 0 var(--spacing-medium);
  border: 4px solid var(--color-lavender);
  border-radius: var(--spacing-x-small);
}

.AccordianContent {
  margin-left: var(--spacing-small);
  margin-top: calc(-1 * var(--spacing-small));
}

.ContentContainer {
  display: flex;
  gap: var(--spacing-medium);
}

.ContentFormItem {
  padding: var(--spacing-medium);
  border: 4px solid var(--color-lavender);
  border-radius: var(--spacing-x-small);
  width: 100%;
}
