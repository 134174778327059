.ActionsColumn {
  white-space: nowrap;
}

.TableWrapper {
  overflow-x: auto;
  position: relative;
}

.Bar {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-end;
}

.HidePagination :global(.dnb-pagination__bar) {
  display: none;
}
