.Container {
  width: 90vw;
  max-width: 90rem;
}

.Container--size-x-large {
  max-width: 90rem;
}

.Container--size-large {
  max-width: 65rem;
}

.Container--size-medium {
  max-width: 50rem;
}

.Container--size-small {
  max-width: 40rem;
}

.Container--centered {
  margin: auto;
}

.Container--stretch {
  width: 100%;
}
