.Status {
  text-transform: capitalize;
  font-weight: 500;
}
.Status--pending {
  color: var(--color-black-80);
}

.Status--denied {
  color: var(--color-fire-red);
}

.Status--approved {
  color: var(--color-success-green);
}
