.Actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing-medium);
}

.Members {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-small);
}

.MemberTile {
  row-gap: var(--spacing-small);
}

.MemberBoxDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-medium);
}

.MemberDetails {
  display: flex;
  align-items: center;
}

.DateDetails {
  text-align: right;
}

.EmailAndDate {
  color: var(--color-black-55);
  font-size: 14px;
}

.Divider {
  color: var(--color-black-8);
}

.MemberBoxActions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-x-small);
}

@media screen and (max-width: 50rem) {
  .Members {
    grid-template-columns: 1fr;
  }

  .Avatar {
    display: none;
  }
}

@media screen and (max-width: 27rem) {
  .MemberBoxActions {
    flex-direction: column;
    align-items: center;
  }
}
