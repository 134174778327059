.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Bar {
  padding: 1rem;
  border-bottom: 1px solid var(--color-mint-green-50);
  text-align: center;
}

.Content {
  display: flex;
  flex: 1;
}

.Navigation {
  padding: 4rem 0;
  font-size: var(--font-size-basis);
  background-color: var(--color-white);
  flex: 0 0 16em;
  border-right: 1px solid var(--color-black-8);
  z-index: 9;
}

.Navigation ul {
  padding: 0;
  margin: 0;
}

.NavLink {
  display: block;
  color: var(--color-sea-green);
  text-decoration: none;
  padding: 1.5rem;
}

.NavLink:hover {
  background-color: var(--color-mint-green-50);
}

.ActiveNavLink,
.ActiveNavLink:hover {
  background-color: var(--color-mint-green);
}

.Main {
  flex: 1;
  /* Eufemia's GlobalStatus overflows */
  overflow-x: hidden;
}

.Main-status {
  padding: 0 2rem;
}

.Main-content {
  padding: 2rem;
}
