.Feedback {
  display: grid;
  gap: 1rem;
}

.Feedback-message {
  background-color: var(--color-black-3);
  padding: 1.5rem;
  border-radius: 0.25rem;
}
