.Status {
  text-transform: capitalize;
  font-weight: 500;
}
.Status--pending {
  color: var(--color-black-80);
}

.Status--denied {
  color: var(--color-fire-red);
}

.Status--approved,
.Status--level1_approved {
  color: var(--color-success-green);
}

.Scopes {
  display: flex;
  gap: calc(var(--spacing-large) - var(--spacing-x-small) / 2);
}

.ScopeDescription {
  font-weight: 500;
}
