.Jumbotron {
  display: grid;
  grid-template-areas:
    'image header'
    'image text';
}

.Jumbotron > figure {
  grid-area: image;
}

.Jumbotron > h1 {
  grid-area: header;
}

.Jumbotron > p {
  grid-area: text;
}

.Shortcuts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: stretch;
}

.Shortcut {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  height: 12rem;
  display: block;
  padding: calc(1.5rem - 2px);
  border: 2px solid transparent;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'header icon'
    'text icon';
}

.Shortcut:hover {
  border-color: var(--color-black);
}

.Shortcut > h3 {
  grid-area: 'header';
}

.Shortcut > p {
  grid-area: text;
}

.Shortcut > span {
  grid-area: icon;
  align-self: center;
}
