.TableContainer {
  padding: var(--spacing-medium);
  border: 4px solid var(--color-pistachio);
  border-radius: var(--spacing-x-small);
}

.ButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-medium);
}

.NoContentContainer {
  padding: var(--spacing-x-large) var(--spacing-medium);
  border: 4px solid var(--color-pistachio);
  border-radius: var(--spacing-x-small);
}
