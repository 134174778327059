.Card {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  border: 1px solid var(--color-black-8);
  background-color: var(--color-white);
  padding: var(--spacing-small);
  width: 100%;
}

.Row {
  flex-direction: row;
}

.Clickable:hover {
  border-color: var(--color-sea-green);
  box-shadow: 0px 0px 0px 2px var(--color-sea-green);
  cursor: pointer;
}

.Clickable:focus {
  outline: none !important;
  border-color: var(--color-sea-green);
  box-shadow: 0px 0px 0px 2px var(--color-sea-green);
}

.Card--selected {
  border-color: var(--color-sea-green);
  box-shadow: 0px 0px 0px 1px var(--color-sea-green);
  cursor: pointer;
}
